import React, { useContext,useState } from "react";

import {
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogActions,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { apiConfig } from "src/apiconfig/ApiConfig";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  dialogMain: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: "520px",
      minHeight: "367px",
      borderRadius:"5px"
    },

    "& .dialogContainer": {
      boxSizing: "border-box",
      padding: "30px",

      "& .imageContainer": {
        textAlign: "center",
      },

      "& .removeTitle": {
        fontSize: "20px",
        fontWeight: 500,
        color: "#FF0B0BDE",
        marginTop: "20px",
        textAlign: "center",
      },

      "& .acceptTitle": {
        fontSize: "20px",
        fontWeight: 500,
        color: "#02AB97DE",
        marginTop: "20px",
        textAlign: "center",
      },

      "& .text": {
        fontSize: "20px",
        fontWeight: 400,
        color: "#636880",
        maxWidth: "300px",
        textAlign: "center",
        margin: "20px auto",
      },

      "& .btnContainer": {
        gap: "20px",
        marginTop: "40px",

        "& .nobtn": {
          width: "100%",
          maxWidth: "156px",
          height: "50px",
          background: "#F2F2F2",
          fontSize: "16px",
          color: theme.palette.secondary,
          fontWeight: 400,
          fontFamily: "Public Sans",
          border: "none",
        },

        "& .yesbtn": {
          width: "100%",
          maxWidth: "156px",
          height: "50px",
          background: "#0856CC",
          fontSize: "16px",
          color: "#fff",
          fontWeight: 400,
          fontFamily: "Public Sans",
          border: "none",
        },
      },
    },
  },
}));

export default function CommonPopup({ type, title, text, open, onClose,onConfirm ,RemoveHandler }) {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext)

  const handleNoButtonClick = () => {

    onClose();
  };

  return (
    <>
  {type === "logout1" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="outlined"
                  className="nobtn"
                  // onClick={handleNoButtonClick}
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button variant="containedPrimary" className="yesbtn" 
                onClick={() => {
                  localStorage.clear();
                  sessionStorage.removeItem('token');
                  auth.userLogIn(false,"")
                  auth.setProfileData("")     
                  window.location.reload();            
                }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
          
        </>
      )}

      {type === "remove" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="outlined"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button variant="containedPrimary" className="yesbtn"
                onClick={() => {
                  RemoveHandler()
                  onClose()
                  }}>
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "accept" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="acceptTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="outlined"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button variant="containedPrimary" className="yesbtn"
                 onClick={() => onConfirm()}>
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

      {type === "decline" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="outlined"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button variant="containedPrimary" className="yesbtn"
               onClick={() => onConfirm()}>
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}

{type === "logout" && (
        <>
          <Dialog open={open} onClose={onClose} className={classes.dialogMain}>
            <Box className="dialogContainer">
              <div className="imageContainer">
                <img src="images/watchlist_popup_img.png" />
              </div>

              <Typography variant="body2" className="removeTitle">
                {title}
              </Typography>

              <Typography variant="body2" className="text">
                {text}
              </Typography>

              <Box className="btnContainer displayCenter">
                <Button
                  variant="outlined"
                  className="nobtn"
                  onClick={() => onClose()}
                >
                  No
                </Button>

                <Button variant="containedPrimary" className="yesbtn" 
                onClick={() => {
                  localStorage.clear();
                  sessionStorage.removeItem('token');
                  auth.userLogIn(false,"")
                  auth.setProfileData("")                 
                  history.push('/')
                }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Dialog>
        </>
      )}



      
    </>
  );
}
