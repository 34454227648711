import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import Partners from "src/views/pages/static/Partners/Partners";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingPage/Index")),
  },

  {
    exact: true,
    path: "/login",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/login/Login")),
  },
  {
    exact: true,
    path: "/unsubscribe",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Unsubscribe.js")),
  },
  {
    exact: true,
    path: "/unsubscribe/:emailName",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Unsubscribe.js")),
  },

  {
    exact: true,
    path: "/login-password",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/login/Loginpassword")),
  },

  {
    exact: true,
    path: "/register",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/signup/Signup")),
  },

  {
    exact: true,
    // guard:true,
    path: "/reset-password",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/resetpassV2/Resetpassword")
    ),
  },

  {
    exact: true,
    path: "/register-password",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/regipassword/regipassword")
    ),
  },

  {
    exact: true,
    // guard:true,
    path: "/forgot",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Auth/forgot/forgot")),
  },

  {
    exact: true,
    guard: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/changepassword/Changepassword")
    ),
  },

  {
    exact: true,
    // guard:true,
    path: "/verify-otp",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/otpverification/otpverify")
    ),
  },

  {
    exact: true,
    path: "/signup-verified",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/verification/Signupverify")
    ),
  },

  {
    exact: true,
    path: "/login-verified",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Auth/verification/Loginverify")
    ),
  },

  {
    exact: true,
    path: "/blog",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Blog/Blog")),
  },
  {
    exact: true,
    path: "/blog/:blogname",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Blog/BlogDetails")),
  },

  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/FAQ/Faq")),
  },
  {
    exact: true,
    path: "/policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Policy/Index.js")),
  },
  {
    exact: true,
    path: "/CareerOptions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CareerOptions")),
  },

  {
    exact: true,
    path: "/tax-fee",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Taxes/Taxes")),
  },

  {
    exact: true,
    path: "/community",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Community/Index")),
  },

  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/Index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/JobSector",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/JobSector/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/careers",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Careers/index")),
  },
  {
    exact: true,
    // guard: true,
    path: "/job/:id",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Careers/Jobdetails")),
  },
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/LandingPage/CreateCourse")
    ),
  },
  {
    exact: true,
    path: "/skill-bridge",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/SkillBridge/Index")
    ),
  },

  {
    exact: true,
    path: "/skill-marketplace",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Skillmarketplace/marketplace")
    ),
  },
  {
    exact: true,
    path: "/skill-marketplace/:categoryName",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Skillmarketplace/marketplace")
    ),
  },
  

  {
    exact: true,
    path: "/search-data",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/DefaultSearch/DefaultSearch")
    ),
  },

  {
    exact: true,
    path: "/view-skill-category",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Skillmarketplace/ViewSkillCategory")
    ),
  },
  {
    exact: true,
    path: "/scanCode",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Blockchain")),
  },
  {
    exact: true,
    path: "/endorsement",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Endorsement")),
  },
  {
    exact: true,
    path: "/view-Qr-code",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Profile/Blockchain")),
  },

  {
    exact: true,
    path: "/watchlist",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Watchlist/Index")),
  },
  // {
  //   exact: true,
  //   path: "/my-resume",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Myresume/Index")),
  // },

  {
    exact: true,
    guard: true,
    path: "/checkout",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Cart/MyCart")),
  },
  {
    exact: true,
    path: "/course-details",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/LandingPage/CreateCourse")
    ),
  },
  {
    exact: true,
    path: "/course-details/:courseName",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/LandingPage/CreateCourse")
    ),
  },

  {
    exact: true,
    path: "/lite-paper",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/static/LightPaper/LightPaper")
    ),
  },

  {
    exact: true,
    path: "/platform-specifics",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/static/LightPaper/PlatformSpecifics")
    ),
  },

  {
    exact: true,
    path: "/data-privacy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/static/LightPaper/DataPrivacy")
    ),
  },

  {
    exact: true,
    path: "/white-paper",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/static/LightPaper/WhitePaper")
    ),
  },

  {
    exact: true,
    path: "/opportunity",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Opportunity/Opportunity")
    ),
  },

  {
    exact: true,
    path: "/watchlist-request",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Watchlist/WatchListRequest")
    ),
  },

  {
    exact: true,

    path: "/subscription",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subscription/Index")
    ),
  },
  {
    exact: true,

    path: "/mydrops",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drops/Index.js")
    ),
  },
  {
    exact: true,

    path: "/course-nft",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/MyCourseNft/index.js")
    ),
  },
  {
    exact: true,
    path: "/subscription-nft",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubcrotionNft/index.js")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/cart",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Cart/Cart")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/PrivacyPolicy")),
  },

  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/TermsAndCondition")),
  },

  {
    exact: true,
    path: "/media",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Media/Media")),
  },
  {
    exact: true,
    path: "/careers",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Career/Career")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/ContactUs")),
  },
  {
    exact: true,
    guard: true,
    path: "/my-subscription-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Subscription/MySubscriptionDetails")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/my-drops-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Drops/MyDropDetails")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/notifications",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Notiifcation/Index")
    ),
  },
  {
    exact: true,
    path: "/gyan-blog",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Blog/BlogSecond")),
  },

  {
    exact: true,
    path: "/skill-badges",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Badges/Index")),
  },

  {
    exact: true,
    path: "/skills",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Skills/Index")),
  },

  {
    exact: true,
    path: "/approval",
    // layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Badges/Approval")),
  },

  {
    exact: true,
    path: "/attendance",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Attendance/Index")),
  },

  // {
  //   exact: true,
  //   path: "/sub-category",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home/Courses/SubCategory")),
  // },
  {
    exact: true,
    path: "/courses/:categoryName",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Courses/SubCategory")),
  },
  
  {
    exact: true,
    path: "/partner-with-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/IAICOE/PartnerWithUS")),
  },
  // {
  //   exact: true,
  //   path: "/category",
  //   layout: HomeLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Skillmarketplace/ViewCategory")
  //   ),
  // },
  {
    exact: true,
    path: "/category/:categoriesName",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Skillmarketplace/ViewCategory")
    ),
  },

  // {
  //   exact:true,
  //   path:'/coes',
  //   layout:HomeLayout,
  //   component: lazy(()=> import("src/views/pages/Home/IAICOE/Coes")),
  //  },
  {
    exact: true,
    path: "/about-coe-program",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/IAICOE/AboutIAICOES")),
  },

  {
    exact: true,
    path: "/existing-coe-partners",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Partners/Partners")),
    
  },
 
  {
    exact: true,
    path: "/existing-coe-partners/:collegeName",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/static/Partners/CollegeData")
    ),
  },
  
 
  {
    exact: true,
    path: "/all-categories",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Skillmarketplace/Viewallcategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Wallet/Wallet")),
  },
  {
    exact: true,
    path: "/ranking",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Ranking")),
  },
  {
    exact: true,
    path: "/activity",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Activity")),
  },
  {
    exact: true,
    path: "/skill-drops",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Drops/CreateDrops")),
  },

  {
    exact: true,
    guard: true,
    path: "/my-tickets",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Ticket/Index")),
  },
  {
    exact: true,
    path: "/catalouge",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Catalogue")),
  },

  {
    exact: true,
    path: "/resource-center",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/ResourceCenter")),
  },

  {
    exact: true,
    path: "/create-ticket",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Ticket/CreateTicket")
    ),
  },

  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/About/About")),
  },

  {
    exact: true,
    path: "/roadmap",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/static/Roadmap")),
  },
  {
    exact: true,
    // guard: true,
    path: "/trendskills-ai",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CareerMatchChatBot")),
  },
  {
    exact: true,
    path: "/payment-history",
    component: lazy(() => import("src/views/pages/Courses/SuccessScreen")),
  },
  {
    exact: true,
    path: "/invoice",
    component: lazy(() => import("src/views/pages/Courses/Invoice")),
  },
  {
    exact: true,
    path: "/payment-bundle-history",
    component: lazy(() => import("src/views/pages/Courses/SuccessScreen")),
  },
  {
    exact: true,
    path: "/media/:mediaName",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent1")),
  },
  {
    exact: true,
    path: "/media2",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent2")),
  },
  {
    exact: true,
    path: "/media3",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent3")),
  },
  {
    exact: true,
    path: "/media4",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent4")),
  },
  {
    exact: true,
    path: "/media5",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent5")),
  },
  {
    exact: true,
    path: "/media6",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent6")),
  },
  {
    exact: true,
    path: "/media7",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent7")),
  },
  {
    exact: true,
    path: "/media8",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent8")),
  },
  {
    exact: true,
    path: "/media9",
    layout: HomeLayout,

    component: lazy(() => import("src/views/pages/static/Media/MediaContent9")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
