import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  makeStyles,
  ListItem,
} from "@material-ui/core";
import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import { IoBagRemoveSharp, IoWallet } from "react-icons/io5";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineSubscriptions,
} from "react-icons/md";
import CommonPopup from "src/views/pages/Dashboard/Watchlist/CommonPopup";
import { FaRegUser, FaUserMinus } from "react-icons/fa";
import { BsLayoutTextWindowReverse } from "react-icons/bs";
import ListIcon from "@material-ui/icons/List";
import { SlBadge } from "react-icons/sl";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import { IoMdNotificationsOutline } from "react-icons/io";
import { SlBookOpen } from "react-icons/sl";
import { PiTicketThin } from "react-icons/pi";
import toast from "react-hot-toast";
import axios from "axios";
import { apiConfig } from "src/apiconfig/ApiConfig";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#fff",
  },
  desktopDrawer: {
    background: "#fff",
    marginTop: "100px",
    minWidth: "250px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  chPassButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "5px",
    marginTop: "10px",
    width: "220px",
    marginLeft: "11px",
    position: "absolute",
    border: "1px dashed",
    bottom: "80px",
    background: "transparent",
    fontWeight: "400",
    padding: "13px 20px",
    fontSize: "13px",
    [theme.breakpoints.down("md")]: {
      bottom: "-152px",
    },
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    borderRadius: "5px",
    marginTop: "10px",
    width: "220px",
    marginLeft: "11px",
    position: "absolute",
    border: "1px dashed",
    bottom: "20px",
    background: "transparent",
    fontWeight: "400",
    padding: "13px 20px",
    fontSize: "13px",
    [theme.breakpoints.down("md")]: {
      bottom: "-213px",
    },
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
  navDetail: {
    color: theme.palette.secondary.main,
    borderRadius: "15px",
    background: "linear-gradient(149deg, #5681F1 10.75%, #1354FC 94.05%)",
    padding: "15px",
    boxShadow: "1px 21px 13px -13px rgba(19,84,252,0.75)",
    "& .avtarBox": {
      gap: "10px",
    },
    "& h6": {
      color: theme.palette.secondary.main,
    },
    "& hr": {
      backgroundColor: "rgba(255, 255, 255, 0.10)",
      margin: "10px 0",
    },
  },
  dailogOpen: {
    "& .MuiPaper-rounded": {
      borderRadius: "15px",
      padding: "50px",
    },
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "70px",
    },
    "& p": {
      fontSize: "18px",
      fontWeight: 400,
      color: theme.palette.primary.light,
      marginTop: "20px",
    },
    "& h3": {
      fontSize: "30px",
      marginTop: "43px",
    },
  },
}));

const sections = [
  {
    items: [
      {
        title: "My Wallet",
        modules: "dashboard",
        icon: MdOutlineAccountBalanceWallet,
        href: "/my-wallet",
      },
      {
        title: "My Profile",
        modules: "dashboard",
        icon: FaRegUser,
        href: "/profile",
      },
      // {
      //   title: "My Resume",
      //   modules: "dashboard",
      //   icon: BsLayoutTextWindowReverse,
      //   href: "/my-resume",
      // },
      
      // {
      //   title: "My Skills",
      //   modules: "dashboard",
      //   icon: ListIcon,
      //   href: "/skills",
      // },
      // {
      //   title: "Create Course NFT",
      //   modules: "dashboard",
      //   icon: SlBookOpen,
      //   href: "/course-nft",
      // },
      // {
      //   title: "My Course NFT",
      //   modules: "dashboard",
      //   icon: MdOutlineSubscriptions,
      //   href: "/subscription-nft",
      // },
      // {
      //   title: "Opportunities",
      //   modules: "dashboard",
      //   icon: IoBagRemoveSharp,
      //   href: "/opportunity",
      // },
      {
        title: "MY SUBSCRIPTIONS",
        modules: "dashboard",
        icon: MdOutlineSubscriptions,
        href: "/subscription",
      },
      {
        title: "MY Drops",
        modules: "dashboard",
        icon: MdOutlineSubscriptions,
        href: "/mydrops",
      },
      // {
      //   title: "WATCHLIST",
      //   modules: "dashboard",
      //   icon: AssessmentOutlinedIcon,
      //   href: "/watchlist",
      // },
      {
        title: "My Badges",
        modules: "dashboard",
        icon: SlBadge,
        href: "/skill-badges",
      },
      {
        title: "My Notifications ",
        modules: "dashboard",
        icon: IoMdNotificationsOutline,
        href: "/notifications",
      },

      {
        title: "My tickets",
        modules: "dashboard",
        icon: PiTicketThin,
        href: "/my-tickets",
      },
      // {
      //   title: "Attendance",
      //   modules: "dashboard",
      //   icon: FaUserMinus,
      //   href: "/attendance",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);
  const [filteredSections, setFilteredSections] = useState([]);
  const [isEducator, setEducator] = useState(false);

  const handleLogoutClose = () => {
    setIsLogout(false);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    navItems();
  }, []);

  const navItems = async () => {
    try {
      const res = await axios({
        url: apiConfig.getUserProfile,
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      });
      if (res?.data?.responseCode === 200) {
        setEducator(true);
        if (res.data.result.userType !== "EDUCATOR") {
          const filteredItems = sections[0].items.filter(
            (item) =>
              item.title !== "Create Course NFT" &&
              item.title !== "My Course NFT"
          );
          setFilteredSections([{ items: filteredItems }]);
        } else {
          setFilteredSections(sections);
        }
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setFilteredSections(sections);
      setEducator(false);
      // toast.error("Error fetching user profile. Please try again later.");
    }
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box style={{ marginBottom: "140px" }}>
        <Box className="sideMenuBox">
          {filteredSections.length > 0 ? (
            filteredSections.map((section, i) => {
              let item = section.items;

              return (
                <List key={`menu${i}`}>
                  {renderNavItems({
                    img: section.img,
                    items: item,
                    pathname: location.pathname,
                  })}

                  <ListItem></ListItem>
                </List>
              );
            })
          ) : (
            <List>
              {sections.map((section, i) => {
                let items = section.items;
                // If the user is not an educator, filter out the "Create Course NFT" item
                if (!isEducator) {
                  items = items.filter(
                    (item) =>
                      item.title !== "Create Course NFT" &&
                      item.title !== "My Course NFT"
                  );
                }
                return (
                  <div key={`menu${i}`}>
                    {renderNavItems({
                      items: items,
                      pathname: location.pathname,
                    })}
                  </div>
                );
              })}
            </List>
          )}
        </Box>
      </Box>

      <Button
        onClick={() => history.push("/change-password")}
        className={classes.chPassButton}
        style={{ borderColor: "#C0C0C0", color: "#262626" }}
      >
        <img
          src="images/passwordbtn_img.svg"
          style={{ marginRight: "10px", fontSize: "20px" }}
        />
        Change Password
      </Button>
      <Button
        onClick={() => setIsLogout(true)}
        className={classes.logoutButton}
        style={{ borderColor: "#FF0909", color: "#FF1818" }}
      >
        <img
          src="images/logoutbtn_img.svg"
          style={{ marginRight: "10px", fontSize: "20px" }}
        />
        Logout
      </Button>
      {isLogout && (
        <CommonPopup
          type="logout"
          open={isLogout}
          onClose={handleLogoutClose}
          title="Logout"
          text="Are you sure you want to log out?"
        />
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box
          anchor="left"
          className={classes.desktopDrawer}
          open
          variant="persistent"
        >
          {content}
        </Box>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
