import React, { useContext, useEffect } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { Box,Typography, makeStyles,} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    footerSection: {
        "& .gyanbatualogo": {
            width: "80px",
            marginRight: "8px",
            cursor: "pointer",
            // [theme.breakpoints.down("xs")]: {
            //   display: "none",
            // },      
          },
          "& .trademarks":{
            "& span":{
                color:"#0856CC",
                cursor:'pointer'
            }
        } 
    },
  
 
   
  }));
export default function FooterCopyRight(){
    const classes = useStyles();
      
    return(
        <Box className={classes.footerSection} >
             <Box className="displayCenter bottomFootertext">
                <RouterLink to="/">
                    <img
                    src="/images/logo.svg"
                    className="gyanbatualogo"
                    ></img>
                </RouterLink>


                <Typography className="trademarks">
                    <span  onClick={() => window.open("https://matrix-secure.in/", "_blank")}>
                    © 2024 Matrix Secure Applications (OPC) Pvt Ltd
                    </span>
                </Typography>
            </Box>
        </Box>
    );
}
