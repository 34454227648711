// import {
//   AppBar,
//   Toolbar,
//   makeStyles,
//   Button,
//   IconButton,
//   Drawer,
//   MenuItem,
//   Box,
//   Container,
//   Menu,
//   Paper,
//   Dialog,
//   Typography,
//   TextField,
//   Select,
//   FormControl,
//   Grid,
//   Avatar,
//   Popover,
//   Divider,
//   Checkbox,
// } from "@material-ui/core";
// import { toast } from "react-hot-toast";
// import { Link, useHistory, useLocation } from "react-router-dom";

// import axios from "axios";
// import React, { useState, useEffect, useRef, useContext, useLayoutEffect } from "react";
// import { AuthContext } from "src/context/Auth";
// import { apiConfig } from "src/apiconfig/ApiConfig";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import { CiSearch } from "react-icons/ci";
// import { RiArrowDropDownLine } from "react-icons/ri";
// import { RxCross2 } from "react-icons/rx";

// const useStyles = makeStyles((theme) => ({
//   transparentTextfield: {
//     "& .MuiOutlinedInput-root": {
//       background: "rgba(255,255,255,0.2)",
//     },

//     "& .MuiOutlinedInput-notchedOutline": {
//       border: "none",
//     },

//     "& .MuiOutlinedInput-input": {
//       "&::placeholder": {
//         color: "rgba(177, 177, 177, 1)",
//       },
//     },

//     "& .MuiInputBase-input": {
//       color: "rgba(255,255,255,0.8)",
//     },
//   },
//   PopOverClass: {
//     "& .MuiPopover-paper": {
//       padding: "20px 0 18px 0 ",
//       width: "100%",
//       maxWidth: "220px",
//       position: "absolute",
//     },
//   },
//   profileMenuItem: {
//     backgroundColor: "white",
//     gap: "10px",
//     alignItems: "center",
//     padding: "0",

//     "& .btnProfile": {
//       whiteSpace: "pre",
//       border: "none",
//       "& h5": {
//         fontSize: "16px",
//         color: "#262626",
//         display: "flex",
//         alignItems: "start",
//       },
//     },
//   },
//   profileAccount: {
//     backgroundColor: "white",
//     gap: "10px",
//     alignItems: "center",
//     marginTop: "10px",
//     "& .myAccTypo": {
//       fontSize: "16px",
//       color: "#262626",
//     },
//   },
//   suggestions: {
//     color: "#262626",
//     position: "absolute",
//     backgroundColor: "#fff",
//     top: "44px",
//     borderRadius: "10px",
//     background: "#fff",
//     boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
//     width: "100%",
//     maxWidth: "400px",
//     "& .suggestionslist": {
//       cursor: "pointer",
//       paddingLeft: "20px",
//       "& li": {
//         marginBottom: "6px",
//       },
//     },
//   },
// }));

// const HeaderSearch = () => {
//   const classes = useStyles();
//   const auth = useContext(AuthContext);
//   const globalSearch = auth?.globalSearch;
//   const setGlobalSearch = auth?.setGlobalSearch;
//   const [suggestions, setSuggestions] = useState([]);
//   const history = useHistory();
//   const location = useLocation();
//   const inputRef = useRef(null);
//   const previousPath = useRef(null);
//   const [userInput, setUserInput] = useState(true);
//   const [autoFocus, setAutoFocus] = useState(false);

//   const skillMarketPlaceRoute =
//     history.location.pathname === "/skill-marketplace" ||
//     history.location.pathname === "/skill-bridge" ||
//     history.location.pathname === "/skill-drops";

//     useLayoutEffect(() => {
//       if (history.location.pathname === "/search-data" && inputRef.current) {
//         setTimeout(() => {
//           inputRef.current.focus();
//         }, 120);
//       }
//     }, [history.location.pathname]);

//   useEffect(() => {
//     if (
//       !(
//         location.pathname === "/search-data" ||
//         location.pathname.startsWith("/search-data/")
//       )
//     ) {
//       // Clear global search when leaving "/search-data"
//       clearSearch();
//     }
//   }, [location.pathname]);

//   useEffect(() => {
//     if (
//       !(
//         history.location.pathname === "/search-data" ||
//         history.location.pathname.startsWith("/search-data/")
//       )
//     ) {
//       previousPath.current = history.location.pathname;
//     }
//   }, [history.location.pathname]);

//   const handleSearch = async (value) => {
//     if (
//       value !== globalSearch &&
//       userInput &&
//       !["/register", "/verify-otp", "/login"].includes(
//         history.location.pathname
//       )
//     ) {
//       setGlobalSearch(value);

//       if (value.trim() !== "") {
//         try {
//           const params = { name: value };
//           const res = await axios.get(apiConfig.getAllCategory, { params });
//           if (res?.data?.responseCode === 200) {
//             setSuggestions(res?.data?.result?.docs);
//           }
//         } catch (error) {
//           console.error("Error fetching suggestions:", error);
//         }

//           history.push("/search-data");

//       } else {
//         clearSearch();
//       }
//     }
//   };

//   const clearSearch = () => {
//     setGlobalSearch("");
//   };

//   const handleChange = (e) => {
//     const value = e.target.value;
//     setUserInput(true);
//     const cleanedValue = value.replace(/ {2,}/g, " ").trimStart();
//     handleSearch(cleanedValue);
//   };

//   return (
//     <FormControl fullWidth style={{ maxWidth: "400px" }}>
//       <TextField
//         variant="outlined"
//         fullWidth
//         className={skillMarketPlaceRoute ? classes.transparentTextfield : ""}
//         placeholder="Search"
//         autoComplete="off"
//         value={globalSearch}
//         inputRef={inputRef}
//         onChange={handleChange}
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <CiSearch style={{ fontSize: "large" }} />
//             </InputAdornment>
//           ),
//           endAdornment: globalSearch && (
//             <InputAdornment position="end">
//               <RxCross2
//                 style={{ fontSize: "20px", cursor: "pointer" }}
//                 onClick={() => {
//                   clearSearch();
//                   history.push("/");
//                 }}
//               />
//             </InputAdornment>
//           ),
//         }}
//         inputProps={{
//           maxLength: 70,
//         }}
//       />
//     </FormControl>
//   );
// };

// export default HeaderSearch;
import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Tab,
  Tabs,
  Box,
  Typography,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { apiConfig } from "src/apiconfig/ApiConfig";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CiSearch } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { useHistory } from "react-router-dom";

const CustomTab = withStyles((theme) => ({
  root: {
    color: "#182230",
    minWidth: "100px !important",
    borderRadius: "8px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    // '&:hover': {
      //   backgroundColor: "rgba(26, 42, 56, 0.1)",
      // },

    "&$selected": {
      color: "#fff",
      backgroundColor: "#1a2a38",
      borderBottom: "none",
    },
  },
  selected: {},
}))(Tab);

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    background: "#F2F4F7",
    borderRadius: "8px",
  },
  imageBox: {
    width: "40px",
    height: "40px",
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "7px",
      objectFit: "cover",
    },
  },
  dataName: {
    fontFamily: "'Inter', sans-serif ",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#182230 ",
    textAlign: "center",
    marginLeft: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const HeaderSearch = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const inputRef = useRef(null);
  const classes = useStyles();
  const [globalData, setData] = useState([]);
  const [page, setpage] = useState(1);
  const tabLabels = ["categories", "subcategories", "courses"];
  const history = useHistory();
  // useEffect(() => {
  //   console.log(`Active tab label: ${tabLabels[activeTab]}`);
  // }, [activeTab]);

  const defaultSearch = async () => {
    try {
      setLoading(true);
      let params = {
        // search: globalSearch,
        // page: page,
        type: tabLabels[activeTab].toLowerCase(),
        // limit: 10,
      };
      if (searchValue != "") {
        params.search = searchValue;
      }
      const res = await axios({
        method: "GET",
        url: apiConfig.globalSearchApi,
        params: params,
      });
      if (res?.data?.responseCode === 200) {
        setLoading(false);
        if (searchValue) {
          const data = res?.data?.result;
          if (activeTab === 0) setCategories(data?.categoryData);
          if (activeTab === 1) setSubcategories(data?.subCategoryData);
          if (activeTab === 2) setCourses(data?.courseData);
          // setPage(res?.data?.result?.page);
          // setTotalpages(res?.data?.result?.totalPages);
        } else {
          setData([]);
        }
      }
    } catch (error) {
      setLoading(false);
      if (error?.response) {
        setData(error?.response?.data?.result);
      } else if (error?.response?.data?.responseCode == "501") {
        setData([]);
      }
      setData([]);
    }
  };

  useEffect(() => {
    defaultSearch();
  }, [searchValue, tabLabels[activeTab]]);

  useEffect(() => {
    if (isPopupOpen) {
      setActiveTab(0);
    }
  }, [isPopupOpen]);

  const handleClick = () => {
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value); // Update the TextField value in state
  };
  const clearSearch = () => {
    setSearchValue("");
    setIsPopupOpen(false);
    handleClose();
  };

  const data = {
    categories: ["Science", "Math", "History"],
    subcategories: ["Physics", "Algebra", "World History"],
    courses: ["Intro to Physics", "Advanced Algebra", "European History"],
  };
  const items = Array.from({ length: 100 }, (_, index) => index + 1);

  const getTabContent = () => {
    if (!searchValue) return <Typography>Please refine your search!</Typography>;

    const filteredData = data[Object.keys(data)[activeTab]].filter((item) =>
      item.toLowerCase().includes(searchValue.toLowerCase())
    );

    const datas =
      activeTab === 0 ? categories : activeTab === 1 ? subcategories : courses;

    if (datas.length === 0) {
      return (
        <>
          <Typography style={{textAlign:"center",marginBottom:"10px"}}>Please refine your search!</Typography>
        </>
      );
    }

    return (
      <div>
        <Box className="scrollableContainer">
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {datas &&
              Array.isArray(datas) &&
              datas.map((item) => (
                <Box
                  key={item}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (activeTab === 0) {
                      history.push({
                        pathname: `/category/${item?.categoryName
                          .replace(/-/g, "_")
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`,
                        state: {
                          categoryId: item?._id,
                          categoryName: item?.categoryName,
                          description: item?.description,
                          bannerImage: item?.bannerImage,
                          categoryImage: item?.categoryImage,
                        },
                      });
                    } else if (activeTab === 1) {
                      history.push({
                        pathname: `/courses/${item?.subCategoryName
                          .replace(/-/g, "_")
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`,
                        state: {
                          id: item?._id,
                          bannerImage: item?.bannerImage,
                          Description: item?.description,
                          short: item?.short_description,
                          name: item?.subCategoryName,
                          image: item?.subCategoryImage,
                        },
                      });
                    } else {
                      history.push({
                        pathname: `/course-details/${item.otherCourseName
                          .replace(/-/g, "_")
                          .replace(/\s+/g, "-")
                          .toLowerCase()}`,
                        state: {
                          id: item?._id,
                        },
                      });
                    }
                    window.scrollTo(0, 0);
                  }}
                >
                  <Box className={classes.imageBox}>
                    <img
                      src={
                        activeTab === 0
                          ? item?.categoryImage ||
                            "/images/searchReactangle.svg"
                          : activeTab === 1
                          ? item?.subCategoryImage ||
                            "/images/searchReactangle.svg"
                          : item?.thumbnail || "/images/searchReactangle.svg"
                      }
                      // src={item?.subCategoryImage ? item?.subCategoryImage : " "}
                      alt="img"
                      className="img"
                    />
                  </Box>
                  <Typography variant="body1" className={classes.dataName}>
                    {/* {item?.subCategoryName ? item?.subCategoryName : " "} */}
                    {activeTab === 0
                      ? item?.categoryName || ""
                      : activeTab === 1
                      ? item?.subCategoryName || ""
                      : item?.otherCourseName || ""}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <div style={{ flex:'1 0 150px' , position: "relative", maxWidth:'400px' }} className="searchContainer">
      <Box>
        <TextField
          placeholder="Search"
          variant="outlined"
          autoComplete="off"
          onClick={handleClick}
          onFocus={handleClick}
          onChange={handleChange}
          value={searchValue}
          fullWidth
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CiSearch style={{ fontSize: "large" }} />
              </InputAdornment>
            ),
            endAdornment: searchValue && (
              <InputAdornment position="end">
                <RxCross2
                  style={{ fontSize: "20px", cursor: "pointer" , zIndex:10 }}
                  onClick={clearSearch}
                />
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: 70,
          }}
        />
      </Box>
      {isPopupOpen && searchValue.length > 1 && (
        <Box
          style={{
            width: "400px",
            maxHeight: "266px",
            position: "absolute",
            backgroundColor: "white",
            zIndex: 1,
            overflow: "hidden",
            borderRadius: "8px",
            marginTop:'8px'
          }}
          // onClick={(e) => e.stopPropagation()} // Prevent Box from closing when clicking inside
        >
          <Box style={{ width: "95%", margin: "0 auto", marginTop: "10px" }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="search tabs"
              // indicatorColor="primary"
              // textColor="primary"
              variant="fullWidth"
              className={classes.tabsRoot}
              TabIndicatorProps={{
                style: {
                  backgroundColor: "transparent",
                },
              }}
            >
              <CustomTab label="Categories" />
              <CustomTab label="Subcategories" />
              <CustomTab label="Courses" />
            </Tabs>
          </Box>
          <Box style={{ backgroundColor: "#fff", color: "#182230" }}>
            <Box style={{ width: "95%", margin: "0 auto", marginTop: "10px" }}>
              {getTabContent()}
            </Box>
          </Box>
        </Box>
      )}
      {/* Click anywhere outside the popup to close it */}
      {isPopupOpen && (
        <div
          onClick={handleClose}
          style={{
            position: "fixed",
            top: 75,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </div>
  );
};

export default HeaderSearch;
